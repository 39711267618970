import React, { useEffect } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import loadable from "./component/Common/loader/loadable";
import Loading from "./component/Common/loader";
import pMinDelay from "p-min-delay";
import { useDispatch, useSelector } from "react-redux";
import {
  CART_URL,
  CHECKOUT_URL,
  ECOMMERCE_API,
  HOME_URL,
  LOGIN_URL,
  ORDERS_URL,
  PRODUCT_DETAILS_URL,
  REGISTER_URL,
  SHOP_URL,
  WISHLIST_URL,
} from "./app/constants";
import axios from "axios";

// All Page Lazy Import
const Fashion = loadable(() => pMinDelay(import("./page/"), 300), {
  fallback: <Loading />,
});
const ShopLeftSideBar = loadable(
  () => pMinDelay(import("./page/shop/shop-left-sidebar"), 300),
  { fallback: <Loading /> }
);
const ProductDetailsTwos = loadable(
  () => pMinDelay(import("./page/product/product-details-two"), 300),
  { fallback: <Loading /> }
);
const Cart = loadable(() => pMinDelay(import("./page/cart/index"), 300), {
  fallback: <Loading />,
});
const CustomerOrder = loadable(
  () => pMinDelay(import("./page/my-account/customer-order"), 300),
  { fallback: <Loading /> }
);
const CheckoutTwos = loadable(
  () => pMinDelay(import("./page/checkout/checkout-two"), 300),
  { fallback: <Loading /> }
);
const WishLists = loadable(
  () => pMinDelay(import("./page/shop/wishList"), 300),
  { fallback: <Loading /> }
);
const OrderSuccesses = loadable(
  () => pMinDelay(import("./page/order/order-success"), 300),
  { fallback: <Loading /> }
);

const Login = loadable(() => pMinDelay(import("./page/login"), 300), {
  fallback: <Loading />,
});
const Register = loadable(() => pMinDelay(import("./page/register"), 300), {
  fallback: <Loading />,
});
const Error = loadable(() => pMinDelay(import("./page/error"), 300), {
  fallback: <Loading />,
});
const ScrollToTop = loadable(
  () => pMinDelay(import("./component/Common/ScrollToTop"), 300),
  { fallback: <Loading /> }
);

const App = () => {
  const brandFilters = useSelector((state) => state.products.brandFilters);
  const colorFilters = useSelector((state) => state.products.colorFilters);
  const sizeFilters = useSelector((state) => state.products.sizeFilters);
  const priceFilter = useSelector((state) => state.products.priceFilter.id);
  const categoryFilters = useSelector(
    (state) => state.products.categoryFilters
  );
  const subCategoryFilters = useSelector(
    (state) => state.products.subCategoryFilters
  );
  const dynamicFilters = useSelector((state) => state.products.dynamicFilters);
  const paginationDetails = useSelector(
    (state) => state.products.paginationDetails
  );
  const totalCount = useSelector((state) => state.products.totalCount);
   console.log(priceFilter,"priceFilter") 
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchProducts() {
      const data = await axios.get(ECOMMERCE_API, {
        params: {
          isGetProducts: true,
        },
      });
      dispatch({
        type: "products/updateProducts",
        payload: { products: data?.data?.data || [] },
      });
    }
    fetchProducts();
  }, [dispatch]);
  useEffect(() => {
    async function fetchCategories() {
      const data = await axios.get(ECOMMERCE_API, {
        params: {
          isGetCategories: true,
        },
      });
      dispatch({
        type: "products/updateCategories",
        payload: { categories: data?.data?.data || [] },
      });
    }
    fetchCategories();
  }, [dispatch]);
  useEffect(() => {
    async function fetchBrands() {
      const data = await axios.get(ECOMMERCE_API, {
        params: {
          isGetBrands: true,
        },
      });
      dispatch({
        type: "products/updateBrands",
        payload: { brands: data?.data?.data || [] },
      });
    }
    fetchBrands();
  }, [dispatch]);
  useEffect(() => {
    async function fetchColors() {
      const data = await axios.get(ECOMMERCE_API, {
        params: {
          isGetColors: true,
        },
      });
      dispatch({
        type: "products/updateColors",
        payload: { colors: data?.data?.data || [] },
      });
    }
    fetchColors();
  }, [dispatch]);
  useEffect(() => {
    async function fetchSizes() {
      const data = await axios.get(ECOMMERCE_API, {
        params: {
          isGetSizes: true,
        },
      });
      dispatch({
        type: "products/updateSizes",
        payload: { sizes: data?.data?.data || [] },
      });
    }
    fetchSizes();
  }, [dispatch]);
  useEffect(() => {
    async function fetchFilteredProducts() {
      const data = await axios.get(ECOMMERCE_API, {
        params: {
          isGetFilteredProducts: true,
          brandFilters: JSON.stringify(brandFilters),
          colorFilters: JSON.stringify(colorFilters),
          sizeFilters: JSON.stringify(sizeFilters),
          priceFilter: JSON.stringify(priceFilter),
          categoryFilters: JSON.stringify(categoryFilters),
          subCategoryFilters: JSON.stringify(subCategoryFilters),
          dynamicFilters: JSON.stringify(dynamicFilters),
          ...paginationDetails,
        },
      });
      let filteredProducts = data?.data?.data?.products || [];


      dispatch({
        type: "products/updateFilteredProducts",
        payload: { filteredProducts },
      });
      dispatch({
        type: "products/updateFilters",
        payload: {
          type: "totalCount",
          totalCount: data?.data?.data?.totalCount|| -1,
        },
      });
      dispatch({
        type: "products/updateAvailableDynamicFilters",
        payload: { availableDynamicFilters: data?.data?.data?.filters || [] },
      });
    }
    fetchFilteredProducts();
  }, [
    dispatch,
    brandFilters,
    colorFilters,
    sizeFilters,
    categoryFilters,
    subCategoryFilters,
    dynamicFilters,
    paginationDetails,
    priceFilter
  ]);
  useEffect(() => {
    dispatch({
      type: "products/updateFilters",
      payload: {
        type: "paginationDetails",
        filters: {
          pagination: true,
          pageNumber: 1,
          dataPerPage: 16,
        },
      },
    });
  }, [totalCount, dispatch]);
  return (
    <>
      <HashRouter>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path={HOME_URL} exact component={Fashion} />
            <Route path={SHOP_URL} exact component={ShopLeftSideBar} />
            <Route
              path={`${PRODUCT_DETAILS_URL}/:id`}
              exact
              component={ProductDetailsTwos}
            />
            <Route path={WISHLIST_URL} exact component={WishLists} />
            <Route path={CART_URL} exact component={Cart} />
            <Route path={CHECKOUT_URL} exact component={CheckoutTwos} />
            <Route path={LOGIN_URL} exact component={Login} />
            <Route path={REGISTER_URL} exact component={Register} />
            <Route path={ORDERS_URL} exact component={CustomerOrder} />
            <Route
              path={`${ORDERS_URL}/:id`}
              exact
              component={OrderSuccesses}
            />
            <Route exact component={Error} />
          </Switch>
        </Router>
      </HashRouter>
    </>
  );
};

export default App;
