import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { convertProducts } from "../../dataConverter/products";
import { getProductsAccumulatedQtyInCart } from "./helper";

// Product Slice
const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        carts: localStorage.getItem("carts")
            ? JSON.parse(localStorage.getItem("carts"))
            : [],
        favorites: localStorage.getItem("favorites")
            ? JSON.parse(localStorage.getItem("favorites"))
            : [],
        compare: [],
        single: null,
        brandFilters: [],
        colorFilters: [],
        sizeFilters: [],
        categoryFilters: [],
        subCategoryFilters: [],
        dynamicFilters: [],
        availableDynamicFilters: [],
        filteredProducts: [],
        categories: [],
        brands: [],
        sizes: [],
        colors: [],
        totalCount: -1,
        paginationDetails: { pagination: true, pageNumber: 1, dataPerPage: 16 },
        priceFilter: {},
    },
    reducers: {
        updateFilters: (state, action) => {
            const { filters, type, totalCount, priceFilter } = action.payload;
            if (type === "brand") {
                state.brandFilters = filters;
            } else if (type === "color") {
                state.colorFilters = filters;
            } else if (type === "size") {
                state.sizeFilters = filters;
            } else if (type === "category") {
                state.categoryFilters = filters;
            } else if (type === "subCategory") {
                state.subCategoryFilters = filters;
            } else if (type === "dynamicFilters") {
                state.dynamicFilters = filters;
            } else if (type === "paginationDetails") {
                state.paginationDetails = filters;
            } else if (type === "totalCount") {
                state.totalCount = totalCount;
            } else if (type === "priceFilter") {
                state.priceFilter = priceFilter;
            }
        },
        updateProducts: (state, action) => {
            const { products } = action.payload;
            state.products = convertProducts(products);
        },
        updateAvailableDynamicFilters: (state, action) => {
            const { availableDynamicFilters } = action.payload;
            state.availableDynamicFilters = availableDynamicFilters;
        },
        updateFilteredProducts: (state, action) => {
            const { filteredProducts } = action.payload;
            state.filteredProducts = convertProducts(filteredProducts);
        },
        updateCategories: (state, action) => {
            const { categories } = action.payload;
            state.categories = categories;
        },
        updateBrands: (state, action) => {
            const { brands } = action.payload;
            state.brands = brands;
        },
        updateSizes: (state, action) => {
            const { sizes } = action.payload;
            state.sizes = sizes;
        },
        updateColors: (state, action) => {
            const { colors } = action.payload;
            state.colors = colors;
        },
        // Get Single Product
        getProductById: (state, action) => {
            let { id } = action.payload;
            let arr = state.products.find(item => item.id === parseInt(id))
            state.single = arr
        },
        // Add to Cart
        addToCart: (state, action) => {
            let { productInfo } = action.payload;
            let productAccumulatedQty = getProductsAccumulatedQtyInCart(productInfo.productId, state.carts) + parseFloat(productInfo.quantity);
            if (productInfo.uploadType == "Offer" && productInfo.availableStock < productAccumulatedQty) {
                Swal.fire(`No Stock Available...! Available Stock - ${productInfo.availableStock}`)
            } else {
                state.carts.push(productInfo)
                localStorage.setItem("carts", JSON.stringify(state.carts));
                Swal.fire('Success', "Added to Cart", 'success')
            }
        },
        // Add to Compare
        addToComp: (state, action) => {
            if (state.compare.length >= 3) {
                Swal.fire({
                    title: 'Failed!',
                    text: 'Compare List is Full',
                    icon: 'warning',
                    showConfirmButton: false,
                    timer: 2500,
                })
                return;
            }

            let { id } = action.payload;

            // Check existance
            let item = state.compare.find(i => i.id === parseInt(id))
            if (item === undefined) {
                // Get Product
                let arr = state.products.find(item => item.id === parseInt(id))
                state.compare.push(arr)
                Swal.fire({
                    title: 'Success!',
                    text: 'Successfully added to Compare List',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2500,
                })
            } else {
                Swal.fire({
                    title: 'Failed!',
                    text: 'Already Added in Compare List',
                    imageUrl: item.img,
                    imageWidth: 200,
                    imageAlt: item.title,
                    showConfirmButton: false,
                    timer: 5000,
                })
            }
        },
        // Update Cart
        updateCart: (state, action) => {
            let { val, id } = action.payload;
            const cartItemIndex = state.carts.findIndex(i => i.id == id);
            const product = state.products.find(i => i.id == state.carts[cartItemIndex]["productId"]);
            if (!product) {
                state.carts = state.carts.filter((item) => item.productId !== product.id);
                Swal.fire(`No Stock Available...! Available Stock - 0, Product Removed From Cart...!!!`)
            } else {
                let productAccumulatedQty = getProductsAccumulatedQtyInCart(state.carts[cartItemIndex]["productId"], state.carts) + parseFloat(val) - parseFloat(state.carts[cartItemIndex]['quantity']);
                if (state.carts[cartItemIndex]["uploadType"] == "Offer" && (product['availableStock'] < productAccumulatedQty)) {
                    Swal.fire(`No Stock Available...! Available Stock - ${product['availableStock']}`)
                } else {
                    state.carts[cartItemIndex]['quantity'] = val
                }
            }
            localStorage.setItem("carts", JSON.stringify(state.carts));
        },
        // Remove Cart
        removeCart: (state, action) => {
            let { id } = action.payload;
            let arr = state.carts.filter(item => item.id !== parseInt(id))
            state.carts = arr
            localStorage.setItem("carts", JSON.stringify(state.carts));
        },
        // Delete from Compare
        delCompare: (state, action) => {
            let { id } = action.payload;
            let arr = state.compare.filter(item => item.id !== parseInt(id))
            state.compare = arr

        },
        // Clear Cart
        clearCart: (state) => {
            state.carts = []
            localStorage.setItem("carts", JSON.stringify(state.carts));
        },
        // Add to Favorite / Wishlist
        addToFav: (state, action) => {
            let { id } = action.payload;

            // Check existance
            let item = state.favorites.find(i => i.id === parseInt(id))
            if (item === undefined) {
                // Get Product
                let arr = state.products.find(item => item.id === parseInt(id))
                arr.quantity = 1
                state.favorites.push(arr)
                localStorage.setItem("favorites", JSON.stringify(state.favorites));
                Swal.fire('Success', "Added to Wishlist", 'success')
            } else {
                Swal.fire('Failed', "Already Added in Wishlist", 'warning')
            }
        },
        // Remove from Favorite / Wishlist
        removeFav: (state, action) => {
            let { id } = action.payload;
            let arr = state.favorites.filter(item => item.id !== id)
            state.favorites = arr
            localStorage.setItem("favorites", JSON.stringify(state.favorites));

        },
    }
})

const productsReducer = productsSlice.reducer
export default productsReducer
